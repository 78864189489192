import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import { useHeaderContext } from "@/contexts/HeaderContext";
import Link from "next/link";
import Image from "next/image";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useContext } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { getDevice } from "@/lib/helpers";
import { getUserTierFromPlan } from "@/lib/getUserTierFromPlan";
import { useRouter } from "next/router";
import pushToDataLayer from "@/lib/pushToDataLayer";
import { pushEvent } from "@/lib/gtm";
import { navItemClick } from "@/gtmEvents/navigation";
import cloudflareLoader from "@/lib/cloudflare-images";

const hoverColors = [
  "border-rust",
  "border-violet",
  "border-acid",
  "border-rose",
];

const _TabContent = ({ children, title }) => (
  <NavigationMenu.Content
    className={clsx(
      "fixed inset-x-0 top-full z-50 bg-white p-6 shadow-sm shadow-gray-200"
    )}
  >
    <div className="max-w-screen-xl">{children}</div>
  </NavigationMenu.Content>
);

const _NavTab = ({ tab, index }) => {
  const { sessionId, guestId, utmString } = useContext(SessionContext);
  const { hoveredTab } = useHeaderContext();
  const { currentUser } = useCurrentUser();
  const router = useRouter();

  const getEventDataFromNav = (itemName, hierarchy, itemLink, clickType) => {
    return {
      userData: {
        identifier: currentUser ? currentUser.id : guestId,
        userTier: getUserTierFromPlan(currentUser ? currentUser.plan : 0),
        isLoggedIn: !!currentUser,
        firstName: currentUser?.firstName || null,
        lastName: currentUser?.lastName || null,
        email: currentUser?.email || null,
      },
      metaData: {
        eventTimestamp: Date.now(),
        sessionId,
        device: getDevice(),
        platform: "website",
        pagePath: router.asPath,
        referrer: document.referrer,
        utmString,
      },
      eventData: {
        itemName,
        itemHierarchy: hierarchy,
        itemLink,
        clickType,
      },
    };
  };

  const handleNavItemClick = (itemName, hierarchy, itemLink, clickType) => {
    const eventData = getEventDataFromNav(
      itemName,
      hierarchy,
      itemLink,
      clickType
    );

    // Push event to GTM
    pushToDataLayer(navItemClick, eventData);

    // Push event to Mixpanel
    pushEvent("nav_item_click", eventData);
  };

  return (
    <NavigationMenu.Item key={tab.id} value={tab.title} className="group">
      <NavigationMenu.Trigger>
        <button className="px-4 pb-4 text-base font-medium">
          <span
            className={clsx(
              "inline-block border-b-4",
              hoveredTab === tab.title
                ? hoverColors[index % hoverColors.length]
                : "border-transparent"
            )}
          >
            {tab.title}
          </span>
        </button>
      </NavigationMenu.Trigger>
      <_TabContent title={tab.title}>
        {tab.title === "Our chefs" ? (
          <div className="ml-6 grid grid-cols-3 gap-y-2">
            {tab.children?.map((chef) => (
              <div key={chef.id} className="flex items-center py-2">
                {chef.element?.image?.length > 0 && (
                  <Link href={`/${chef.nodeUri}`}>
                    <a
                      onClick={() =>
                        handleNavItemClick(
                          chef.title,
                          `${tab.title}/${chef.title}`,
                          `/${chef.nodeUri}`,
                          "click"
                        )
                      }
                    >
                      <div className="flex items-center space-x-4">
                        <Image
                          src={chef.element.image[0]?.url}
                          alt={chef.title}
                          width={64}
                          height={64}
                          className="rounded-full object-cover"
                          loader={cloudflareLoader}
                          priority
                        />
                        <div className="whitespace-nowrap font-body text-sm text-gray-800">
                          {chef.title}
                        </div>
                      </div>
                    </a>
                  </Link>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="ml-6 grid min-h-[24rem] grid-cols-4 gap-x-4">
            {tab.children?.map((category) => (
              <div key={category.id} className="space-y-2">
                <h3 className="pt-2 font-semibold text-gray-800">
                  {category.title}
                </h3>
                <ul className="space-y-1">
                  {category.children?.map((link) => (
                    <li key={link.id}>
                      <Link href={`/${link.nodeUri}`}>
                        <a
                          className="font-body text-sm text-gray-500 hover:underline"
                          onClick={() =>
                            handleNavItemClick(
                              link.title,
                              `${tab.title}/${category.title}/${link.title}`,
                              `/${link.nodeUri}`,
                              "click"
                            )
                          }
                        >
                          {link.title}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </_TabContent>
    </NavigationMenu.Item>
  );
};

const NavBar = ({ primaryNavigation }) => {
  const { setHoveredTab, hoveredTab } = useHeaderContext();

  return (
    <div className="flex items-center">
      <NavigationMenu.Root
        delayDuration={0}
        onValueChange={setHoveredTab}
        value={hoveredTab}
      >
        <NavigationMenu.List className="relative z-50 flex pl-8">
          {Array.isArray(primaryNavigation) &&
            primaryNavigation.map((tab, index) => (
              <_NavTab key={tab.id} tab={tab} index={index} />
            ))}
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </div>
  );
};

export default NavBar;
